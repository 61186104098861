import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { sync } from 'vuex-router-sync'
import vuetify from "./plugins/vuetify";
import helpers from "./helpers.js";
import oauth from "./util/oauthProviders.js";
import analytics from "./util/analytics.js";
import services from "./services";
//import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies'
import { Duration, Interval, DateTime } from "luxon";
import { initializeApp } from "firebase/app"
import { getAuth, initializeAuth, indexedDBLocalPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";

import { EventBus } from '@/plugins/eventbus.js'; 
//
import getBrowserLocale from "@/util/i18n/browser-locales"
import i18n from './i18n'
import VuetifyToast from 'vuetify-toast-snackbar'
import { stringify } from "querystring";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

Vue.config.productionTip = false;

const plugin = {
  install(Vue) {
    Vue.prototype.$helpers = helpers; // we use $ because it's the Vue convention
    Vue.prototype.$oauth = oauth; // we use $ because it's the Vue convention
    Vue.prototype.$analytics = analytics; // we use $ because it's the Vue convention
  }
};

//const unsync = sync(store, router) // done. Returns an unsync callback fn

// note: this is serious performance penalty. Around 10-20 lighthouse points!
/*Vue.use(VueGtag, {
  config: { id: tenant.analyticsPropertyId },
  appName: 'sodisp',
  enabled: true || process.env.NODE_ENV === 'production',
}, router);
*/
analytics.sync(router);

const browserLocale = getBrowserLocale({ countryCodeOnly: true });
console.log('Browser locale', browserLocale);

Vue.use(VueCookies);
Vue.$cookies.config('30d');

var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
const firebaseApp = initializeApp(config);
//const auth = getAuth(firebaseApp);
// note: this alternative solution prevents a major iframe js to load on mobile. As Oauth popup isn't required anyway we can disable it.
// see https://github.com/firebase/firebase-js-sdk/issues/4946 for notes on this
const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence]
});
onAuthStateChanged(auth, async user => {
  //console.log('FIREBASE onAuthStateChanged', user);
  await store.dispatch("setUser", user);
  EventBus.$emit('login-state-change', user);
});

const user = auth.currentUser;

/*const user = auth.currentUser;

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};*/

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

  mounted() {
    services.enableInterceptor();
  },

  data: {
  },

  i18n,

  computed: {
    isLoading() {
      return services.isLoading;
    }
  }
}).$mount("#app");

// Global error logging
Vue.config.errorHandler = function(error, vm, info) {  
  var message = `Error in ${info}: ${error.toString()}\nStack: ${error.stack}`;
  console.error('Error detected:', error.toString());
  if (error.toString() == 'Failed to load resource: net::ERR_FAILED' || error.toString() == 'Error: Network Error') {
    console.log('-- should have reloaded the page? --');
  }
  analytics.exception(message);
};
window.addEventListener('unhandledrejection', function(event) {
  var message = `Error in ${event.promise} promise: ${event.reason}.`;
  console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
  analytics.exception(message);
});
window.onerror = function (msg, url, line, col, error) {
  var message = `Error in ${url} (${line}:${col}).\nMessage: ${msg}\nError:: ${error}.`;
  console.error('Unhandled error', message);
  analytics.exception(message);
}

Vue.use(plugin);

Vue.use(VuetifyToast, {
	x: 'right', 
	y: 'top', 
	color: 'info', // default
	icon: 'info',
	iconColor: '', // default
	classes: [
		'body-2'
	],
	timeout: 3000, // default
	dismissable: true, // default
	multiLine: false, // default
	vertical: false, // default
	queueable: false, // default
	showClose: false, // default
	closeText: '', // default
	closeIcon: 'fa-times-circle', // default
	closeColor: '', // default
	slot: [], //default
	shorts: {
		custom: {
			color: 'purple'
		}
	},
	property: '$toast' // default
});

Vue.filter('striphtml', function (value) {
  if (!value) {
    return value;
  }
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

Vue.filter('lowercase', function (value) {
  if (!value) {
    return value;
  }
  return value.toLowerCase();
});
Vue.filter('uppercase', function (value) {
  if (!value) {
    return value;
  }
  return value.toUpperCase();
});

Vue.filter('titleize', function (value) {
  if (!value) {
    return value;
  }
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()).replace('_', ' ');
});

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
});

Vue.filter('license', function (value) {
  if (!value) return;
  if (value === 'BASIC') return 'STANDARD';
  return value.toUpperCase();
});

Vue.filter('replace', function (value, template) {
  if (!value) {
      return;
  }

  return value.replace(
    /{(\w+)}/g, 
    (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
    template.hasOwnProperty(placeholderWithoutDelimiters) ? 
    template[placeholderWithoutDelimiters] : placeholderWithDelimiters
  );
  
});

Vue.filter('int', function (value) {
  if (value === undefined) {
    return '0';
  }
  return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0});
});

Vue.filter('distance', function (value, unitType, ignoreUnit, activityTypes) {
  var forceMetric = activityTypes && !Array.isArray(activityTypes) && ['SWIMMING', 'ROWING'].some(x => x == activityTypes || x === activityTypes[0]);
  var unitString = '';
  if (value === undefined || value == null) {
    return '0';
  }
  if (activityTypes && !Array.isArray(activityTypes) && ['ROWING'].some(x => x == activityTypes || x === activityTypes[0])) {
    return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' m';
  }
  if (activityTypes && ['STAIR_CLIMBING'].some(x => x == activityTypes || x === activityTypes[0])) {
    return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' stairs';
  }
  if (unitType === 'IMPERIAL' && !forceMetric) {
      value = (value/1609.344);
      if (!ignoreUnit) {
        unitString = ' mi';
      }
  }
  else {
    value = (value/1000);
    if (!ignoreUnit) {
      unitString = ' km';
    }
  }
  return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 2}) + unitString;
});

Vue.filter('elevation', function (value, unitType, ignoreUnit) {
  var unitString = '';
  if (value === undefined) {
    return '0';
  }
  if (unitType === 'IMPERIAL') {
      value = (value*3.2808399);
      if (!ignoreUnit) {
        unitString = ' ft';
      }
  }
  else {
    value = value;
    if (!ignoreUnit) {
      unitString = ' m';
    }
  }
  return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + unitString;
});

Vue.filter('time', function (value) {
  if (!value) {
    value = 0;
  }
  var duration = Duration.fromObject({ seconds: Math.round(value) }).shiftTo('hours', 'minutes', 'seconds');
  return duration.toFormat('hh:mm:ss');
});

Vue.filter('duration', function (value, compact) {
  if (!value) {
    return '-';
  }
  if (value < 0) {
    return '-';
  }
  const dayInS = 24*3600;
  if (value >= dayInS) {
    const days = Math.floor(value / dayInS).toFixed(0);
    var duration = Duration.fromObject({ seconds: Math.round(value) }).shiftTo('days', 'hours', 'minutes', 'seconds');
    if (compact) { 
      return duration.toFormat("d'd' h'h'");
    }
    return duration.toFormat("d'd' h'h' m'm'");
  }

  var duration = Duration.fromObject({ seconds: Math.round(value) }).shiftTo('hours', 'minutes', 'seconds');
  if (compact) { 
    return duration.hours > 0 ? duration.toFormat("h'h' m'm'") : duration.toFormat("m'm'");
  }

  var format = "h'h' m'm' s's'";
  if (duration.minutes == 0 && duration.seconds == 0){
    format = format.replace(" m'm' s's'", "");
  }
  else if (duration.seconds == 0){
    format = format.replace(" s's'", "");
  }
  if (duration.hours == 0){
    format = format.replace("h'h' ", "");
  }

  //return duration.hours > 0 ? duration.toFormat("h'h' m'm' s's'") : duration.toFormat("m'm' s's'");
  return duration.toFormat(format);
});


Vue.filter('pace', function (value, unitType, activityType) {
  if (value === undefined || !value || value == 0) {
    return '-';
  }
  if (activityType && Array.isArray(activityType)) {
    // check to see if array is passed in and if so use first element
    activityType = activityType[0];
  }
  var unitDisplay = 'min/km';
  if (activityType === 'ROWING') {
    value = (value/2.0);
    unitDisplay = 'min/500m';
  }
  else if (unitType === 'IMPERIAL') {
    value = (value*1.609344);
    unitDisplay = 'min/mi';
  }
  var duration = Duration.fromObject({ seconds: Math.round(value) }).shiftTo('minutes', 'seconds');
  //var duration = Duration.fromObject({ seconds: Math.round(value) }).shiftTo('minutes', 'seconds').toObject();
  if (duration.minutes > 0) { 
    //return `${duration.minutes}:${(duration.seconds+'').padStart(2, '0')} ${unitDisplay}`;
    return duration.toFormat("m:ss") + ' ' + unitDisplay;
  }
  return duration.toFormat("'0:'ss") + ' ' + unitDisplay;
  return `0:${duration.seconds}`;
});

Vue.filter('speed', function (value, unitType, activityType) {
  if (value === undefined || !value || value == 0) {
    return '-';
  }
  if (activityType && Array.isArray(activityType)) {
    // check to see if array is passed in and if so use first element
    activityType = activityType[0];
  }
  if (activityType === 'STAIR_CLIMBING') {
    value = (value*1000.0) / 60.0;
    //value = (value*3.6) * 60.0;
    return `${value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} steps/min`;
  }
  if (activityType === 'ROWING') {
    return `${value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} SPM`;
  }

  var unitDisplay = 'km/h';
  if (unitType === 'IMPERIAL') {
    value = (value/1.609344);
    unitDisplay = 'mi/h';
  }
  return `${value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})} ${unitDisplay}`;
});

Vue.filter('trim', function (value, length) {
  if (value && value.length > length) {
    return value.substring(0, length) + "...";
  }
  return value;
});

Vue.filter('points', function (value) {
  if (value) {
    return (value/1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1});
  }
  return '-';
});


Vue.filter('ago', function (value) {
  var dateTime = DateTime.fromISO(value);
  return dateTime.toRelative({});
});

Vue.filter('localDate', function (value, format, ignoreTimeZone = false) {
  if (value) {
    var luxonFormat = format
    // convert moment to luxon (legacy)
    if (format === 'L') luxonFormat = DateTime.DATE_SHORT;
    if (format === 'L LT') luxonFormat = DateTime.DATETIME_SHORT;
    if (format === 'L LTS') luxonFormat = DateTime.DATETIME_SHORT_WITH_SECONDS;
    if (format === 'L LTS Z') luxonFormat = {...DateTime.DATETIME_MED_WITH_SECONDS, timeZoneName: 'short'};
    if (format === 'ddd L') luxonFormat = {...DateTime.DATE_MED, weekday: 'short' };
    if (format === 'ddd L LTS Z') luxonFormat = {...DateTime.DATETIME_MED_WITH_SECONDS, weekday: 'short', timeZoneName: 'short'};

    var dateTime = DateTime.fromISO(value, { setZone: ignoreTimeZone });
    return dateTime.toLocaleString(luxonFormat);
  }
  return '-';
});

Vue.component('dynamic-content', {
  template: '<div></div>',
  props: {
    html: String
  },
  mounted() {
    let { render, staticRenderFns } = Vue.compile(`<div>${this.html}</div>`);
    new Vue({ el: this.$el, render, staticRenderFns, router })
  }
})
 