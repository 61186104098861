<template>
  <div v-if="!isEmbeddedInApp" id="footer">
    <v-footer
      v-if="!isEmbedded && tenant.isDefault"
      dark
      padless
    >
      <v-row
        justify="center"
        no-gutters
        
      >
        <v-col cols="12" class="footer-row">
          <v-btn
            v-for="(item, i) in $store.state.nav['footer-default']"
            :key="i"
            :to="item.to"
            color="white"
            text
            rounded
            class="my-2"
          >
            {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
          </v-btn>
        </v-col>
        <v-col 
          class="copyright-container py-4 text-center white--text footer-row"
          cols="12"
        >
          <img src="https://sodisp.imgix.net/web/logo-black.svg" class="float-left mx-2" width="100" height="58" alt="Logo sodisp" />
          &copy; {{ new Date().getFullYear() }} — <a href="https://sodisp.com">sodisp.com</a>
          <div>
          <router-link to="/privacypolicy" class="white--text">{{$t('nav.footer.privacy')}}</router-link>
          |
          <router-link to="/cookiepolicy" class="white--text">{{$t('nav.footer.cookies')}}</router-link>
          |
          <router-link to="/termsofservice" class="white--text">{{$t('nav.footer.terms-of-service')}}</router-link>
          |
          <LanguageMenu/>

          </div>
        </v-col>
      </v-row>
    </v-footer>

    <v-footer
      v-else-if="tenant.id === 'cofi'"
      class="custom-footer"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="(item, i) in $store.state.nav['footer-cofi']"
          :key="i"
          :to="item.to"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
        </v-btn>
        <v-col class="copyright-container py-4 text-center" cols="12">
          Download our app:
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:120px;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a class="ml-2" href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:150px; margin: -9px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
        <v-col 
          class="copyright-container py-4 text-center"
          cols="12"
        >
          <img v-if="false" src="https://sodisp.imgix.net/web/logo.svg" class="float-left mx-2" width="100" height="58" alt="Logo sodisp" />
          &copy; {{ new Date().getFullYear() }} — <a :href="tenant.homeUrl">{{tenant.name}}</a>
          <div>
          <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
          |
          <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
          |
          <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          |
          <LanguageMenu color="black" style="color:black !important;"/>

          </div>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="tenant.id == 'asdeporte' || tenant.id == 'trimx'"
      class="custom-footer"
      padless
    >
      <v-row justify="center w-100p" no-gutters>
        <v-col cols="12">
        <v-btn
          v-for="(link, i) in $store.state.nav.footer_embedded"
          :key="i"
          :to="link.to"
          color="primary"
          text
          rounded
          class="my-2 nav-item"
        >
          {{ link.text_key ? $t('nav.header.' + link.text_key) : link.text }}
        </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="w-100p">
        <v-col 
          class="py-4 px-4 text-center legal-info d-flex flex-column flex-sm-row align-center"
          cols="12"
        >
          <div class="justify-start align-start align-md-end powered-by">
            <h4>Powered by</h4>
            <a href="https://sodisp.com" target="_blank"><img src="https://sodisp.imgix.net/web/logo-sodisp.svg" class="mx-2 my-2" style="max-width:100px;" alt="Logo sodisp"/></a>
          </div>
          <span>{{ new Date().getFullYear() }} — <a href="https://sodisp.com" target="_blank">sodisp.com</a></span>
          <v-spacer/>
          <div>
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            |
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          </div>
          <v-spacer/>
          <LanguageMenu style="color:black !important;"/>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="!tenant.isDefault"
      class="custom-footer"
      padless
    >
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, i) in $store.state.nav.footer_embedded"
          :key="i"
          :to="link.to"
          color="primary"
          text
          rounded
          class="my-2 nav-item"
        >
          {{ link.text_key ? $t('nav.header.' + link.text_key) : link.text }}
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="8" class="py-4 px-4 powered-by">
          <h4>Powered by <a href="https://sodisp.com" target="_blank">sodisp - Challenge Platform</a></h4>
          sodisp (short for <strong>so</strong>cial <strong>di</strong>stancing <strong>sp</strong>orts) is the most powerful platform to host endurance sports events and challenges. We offer Strava, Garmin, Polar and Fitbit integration, 6 challenge modes and numerous powerful features to create engaging virtual experiences! <a href="https://sodisp.com" class="" target="_blank">Learn how  &raquo;</a>
        </v-col>
        <v-col cols="12" md="4" class="py-4 px-4 d-flex flex-column justify-start align-start align-md-end powered-by">
          <h4>Powered by</h4>
          <a href="https://sodisp.com" target="_blank"><img src="https://sodisp.imgix.net/web/logo-sodisp.svg" class="mx-2 my-2" style="max-width:100px;" alt="Logo sodisp"/></a>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col 
          class="py-4 px-4 text-center legal-info d-flex flex-column flex-sm-row align-center"
          cols="12"
        >
          <span>{{ new Date().getFullYear() }} — <a href="https://sodisp.com" target="_blank">sodisp.com</a></span>
          <v-spacer/>
          <div>
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            |
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          </div>
          <v-spacer/>
          <LanguageMenu style="color:black !important;"/>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer
      v-else-if="isEmbedded"
      padless
      class="py-4"
    >
      <v-row no-gutters>
        <v-col cols="12" class="px-4 powered-by d-flex flex-column flex-sm-row align-center">
          <h4>Powered by <a href="https://sodisp.com" target="_blank">sodisp - Challenge Platform</a></h4>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col 
          class="px-4 text-center legal-info d-flex flex-column flex-sm-row align-center"
          cols="12"
        >
          <span>&copy; {{ new Date().getFullYear() }} — <a href="https://sodisp.com" target="_blank">sodisp.com</a></span>
          <v-spacer/>
          <div>
            <router-link to="/privacypolicy" class="">{{$t('nav.footer.privacy')}}</router-link>
            |
            <router-link to="/cookiepolicy" class="">{{$t('nav.footer.cookies')}}</router-link>
            |
            <router-link to="/termsofservice" class="">{{$t('nav.footer.terms-of-service')}}</router-link>
          </div>
          <v-spacer/>
          <LanguageMenu style="color:black !important;"/>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<style lang="scss">

  /* CLS improvements:  */
  #footer { 
    min-height: 144px; height: 144px;
    .footer-row { height: 52px; }
    .footer-row.copyright-container { height: 92px; }
  }

  @media (max-width: 500px) {
    #footer { 
      min-height: 272px; height: 272px;
      .footer-row { height: 156px; }
      .footer-row.copyright-container { height: 116px; }
    }
  }  
</style>

<script>
  import LanguageMenu from "@/components/LanguageMenu.vue";
  import tenants from '@/data/tenants.config'
  const tenant = tenants.current();

  export default {
    props: {
    },
    components: {
      LanguageMenu,
    },
    data: () => ({
      tenant: tenant,
    }),
    mounted() {
    },
    methods: {
    },
    computed: {
      isEmbedded() {
        return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
      },
      isEmbeddedInApp() {
        return this.$route.query.view === 'app' || this.$store.state.view == 'app';
      },
    }

  }
</script>