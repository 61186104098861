<template>
  <v-app id="app" :class="`${isEmbedded?'view--embedded':'view--normal'} tenant--${tenant.id} size--${$vuetify.breakpoint.name} context--${$store.state.context}`">
    <div v-if="tenant.isDefault && !isEmbedded" class="header-announcement">
      <span>📢 New: <a href="https://corporatefitness.app" target="_blank">CorporateFitness.app</a> for connected corporate fitness programs.</span>
      <a href="https://corporatefitness.app" target="_blank">Learn More &raquo;</a>
    </div>
    <v-app-bar
      v-if="!isEmbedded"
      app
      color="header"
      :class="$route.meta.toolbarClass"
      dark
      extended
      extension-height="4"
      elevate-on-scroll
    >

      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs" @click="drawer = true"><v-icon>fa-bars</v-icon></v-app-bar-nav-icon>
      <router-link to="/" class="pa-0 logo">
        <img v-if="false && $vuetify.breakpoint.xsOnly" src="https://sodisp.imgix.net/web/logo-white.svg" alt="Logo sodisp" class="ml-4" height="32" width="89" />
        <span v-else-if="tenant.id === 'cofi'" class="d-flex">
          <img v-if="true" :src="tenant.headerLogoUrl" alt="Logo CorporateFitness.app" class="ml-4" height="40" style="max-width:200px;" />
          <span class="ml-4 header-logo" style="line-height:40px;">Corporate<strong>Fitness</strong>.app</span>
        </span>
        <img v-else :src="tenant.headerLogoUrl" alt="Logo sodisp" class="ml-4" height="32" style="max-width:200px;" />
      </router-link>
      <!--<v-toolbar-title><v-btn text to="/" exact class="px-0">sodisp</v-btn></v-toolbar-title>--> 
      <v-spacer/>
      
      <template v-if="!$vuetify.breakpoint.xsOnly">
        <template v-for="(item, i) in $store.state.nav['menu-'+tenant.navPostfix]">
          <v-btn :key="i" text :to="item.to"><v-icon class="mr-2 d-none">{{item.icon}}</v-icon>{{$t('nav.header.' + item.text_key)}}</v-btn>
        </template>
        <v-menu offset-y v-if="tenant.solutionsMenuKey">
          <template v-slot:activator="{ on }">
            <v-btn text class=" ml-0" v-on="on">
              {{$t('nav.header.solutions')}}
              <v-icon color="white" class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <template v-for="(item, i) in $store.state.nav[tenant.solutionsMenuKey]">
                <v-divider v-if="item.divider" :key="i" class="my-4" />
                <v-list-item v-else :key="i" link :to="item.to" exact>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text_key ? $t('nav.account.' + item.text_key) : item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>              
      </template>
      <AccountMenu />
      <v-progress-linear slot="extension"  v-if="isHttpRequestLoading" indeterminate color="progress" class="ajax-loader ma-0"></v-progress-linear>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list-item to="/">
        <v-list-item-content>
          <v-list-item-title v-if="tenant.id === 'cofi'" class="title">
            <div><img src="/images/logo-cofi-sidebar.png" alt="Logo CorporateFitness.app" width="200" /></div>
          </v-list-item-title>
          <v-list-item-title v-else class="title">
            <div><img :src="tenant.logoUrl" alt="Logo sodisp" width="200" /></div>
            <!--<strong>sodisp</strong>.com-->
          </v-list-item-title>
          <v-list-item-subtitle v-if="tenant.id === 'cofi'">
            Connected Corporate Fitness
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            Challenge Platform
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark class="my-4"/>
      <v-list dense>
        <template v-for="(item, i) in $store.state.nav['drawer-'+tenant.navPostfix]">
          <v-list-item v-if="item.heading || item.heading_key" :key="i">
            <v-list-item-content>
              <v-list-item-title class="subtitle">
                {{ item.heading_key ? $t('nav.header.' + item.heading_key) : item.heading }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-4" />
          <v-list-item v-else :key="i" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text_key ? $t('nav.header.' + item.text_key) : item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-if="tenant.id === 'cofi'" class="mt-8">
          <v-btn color="primary" block to="/eventmanager/create">Create an Event</v-btn>
        </v-list-item>
      </v-list>
 
      <!--<template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block to="/me">My Profile</v-btn>
        </div>
      </template>   -->  
    </v-navigation-drawer>    
    <!-- <v-progress-linear v-if="isHttpRequestLoading" indeterminate color="yellow darken-2" class="ajax-loader"></v-progress-linear> -->
    <div v-if="$route.meta.contentLayout" id="page-hero" class="container px-0 pt-6 pt-md-12 white--text container--fluid">
      <div class="container">
        <h1 v-if="!pageImage" class="display-2">{{pageTitle}}</h1>
        <v-row v-if="pageImage">
          <v-col cols="12" sm="8" class="d-flex">
            <h1 class="display-2 align-self-end" :class="{'display-1': $vuetify.breakpoint.xs}">{{pageTitle}}</h1>
          </v-col>
          <v-col cols="12" sm="4" class="align-center">
            <img class="width-50p illustration" :src="pageImage" :alt="pageTitle" /> 
          </v-col>
        </v-row>
      </div>
    </div>
    <router-view id="content" v-if="noLayout"></router-view>
    <v-main v-if="false && !noLayout" id="content">
      <v-container>
          <router-view></router-view>
      </v-container>
    </v-main>
    <v-container v-if="!noLayout" id="content">
      <router-view></router-view>
    </v-container>
<!--     <v-main v-if="!$route.meta.noLayout" id="content">
      <v-container>
        <v-row>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main> -->
    <Footer />
    <ChatBot/>
  </v-app>
</template>

<style v-if="tenant.customCss" :src="`/styles/${tenant.customCss}`"></style>
<!--<style src="./assets/css/vruk.css"></style>-->

<style src="./assets/css/site.css"></style>
<style src="./styles/global.scss" lang="scss"></style>
<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .ajax-loader.v-progress-linear {
    /*top: 64px;*/
  }
  .v-app-bar a.logo { line-height: 0;}
  .v-toolbar__title .v-btn { text-transform: inherit !important; line-height: 18px; font-weight: 700;; font-size: 18px; letter-spacing: 0 !important; }
  .v-toolbar__title .v-btn:before { display: none; }
  .v-toolbar__content, .v-toolbar__extension { padding: 0 !important; }
  /*.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child { margin-right: 0!important;}*/
  .v-application--is-ltr .v-toolbar__content > .v-btn:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn:last-child { margin-right: 8px!important;}
  body header.v-app-bar--is-scrolled {
    background: linear-gradient(to right, var(--v-header-base), var(--v-gradient-base)) !important; 
  }

  .view--embedded {
    .v-main__wrap > .container { padding-top: 0;}
    .v-breadcrumbs { padding-top: 0; }
    #content { min-height: 300px;}
  } 

  /* CLS improvements:  */
  #content { min-height: 900px;}
  #content > .v-main__wrap { min-height: 900px;}
  #content > .v-main__wrap > div.container { min-height: 900px;}
  #content > .v-main__wrap > div.container > div { min-height: 900px;}
  .v-application--wrap > #page-hero { height: 255px;}

  @media (max-width: 500px) {
    .v-application--wrap > #page-hero { height: 258px;}
  }

</style>

<script>
  import { EventBus } from '@/plugins/eventbus.js';
  import AccountMenu from "@/components/AccountMenu.vue";
  import LanguageMenu from "@/components/LanguageMenu.vue";
  import ChatBot from "@/components/ChatBot.vue";
  import Footer from "@/Footer.vue";
  import tenants from '@/data/tenants.config'
  const tenant = tenants.current();

  export default {
    props: {
      source: String,
    },
    components: {
      AccountMenu,
      LanguageMenu,
      ChatBot,
      Footer,
    },
    data: () => ({
      drawer: false,
      titleOverride: null,
      imageOverride: null,
      tenant: tenant,
    }),
    mounted() {
      if (tenant.customCss) {
        // Must add as dynamic element to prevent webpack from optimizing this away
        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `/styles/${tenant.customCss}`;
        document.head.appendChild(link);
      }
      if (tenant.iOSAppStoreId){
        this.addMetaTag("apple-itunes-app", `app-id=${tenant.iOSAppStoreId}`);
      }
      if (tenant.androidPackageId){
        // todo: see https://developers.google.com/web/fundamentals/app-install-banners/native
      }
      if (tenant.favIconUrl) {
        document.getElementById('favicon').href = tenant.favIconUrl;
      }

      let params = new URLSearchParams(window.location.search);
      const view = params.get("view"); // note: this.$route.query.view doesn't work on hard-refreshes!
      if (view) {
        this.$store.commit("setViewMode", view);
      }

      window.addEventListener('click', event => {
        // ensure we use the link, in case the click has been received by a subelement
        let { target } = event;
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that do not reference external resources
        if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
        
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const to = url.pathname
          if (window.location.pathname !== to && event.preventDefault) {
            event.preventDefault()
            this.$router.push(to)
          }
        }
      });
    },
    methods: {
      addMetaTag(name, content) {
        let meta = document.createElement('meta');
        meta.name = name;
        meta.content = content;
        document.head.appendChild(meta);

      },
      resetViewMode() {
        this.$store.dispatch('setViewMode', null);
      },
    },
    computed: {
      noLayout() {
        return this.$route.matched.some(route => route.meta.noLayout); //$route.meta.noLayout;
      },
      isHttpRequestLoading () {
        return this.$store.getters.loading
      },
      pageTitle() {
        return this.titleOverride || this.$route.meta.title;
      },
      pageImage() {
        return this.imageOverride || (this.$route.meta.illustration ? 'https://sodisp.imgix.net/web/illustrations/'+this.$route.meta.illustration : null);
      },
      isEmbedded() {
        return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.$route.query.view === 'app' || this.$store.state.view == 'app';
      },
    }

  }
</script>